import dynamic from "next/dynamic";
import Script from 'next/script';
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";

const Sports = {
    Futbol: dynamic(() => import('./Futbol')),
    Rugby: dynamic(() => import('./Rugby'))
}

const OptaWidget = (props) => {

    const dataContext = useContext(TemplateContext);
    const { content: formatContent, ...rest } = dataContext || {};
    // si no tengo props, busco contexto
    if(Object.keys(props).length === 0 && !!formatContent) {
            props = { 
                ...props,
                sport: formatContent?.storyContainer?.data?.mam?.sport,
                competition: formatContent?.storyContainer?.data?.mam?.category?._id,
                season: formatContent?.storyContainer?.data?.mam?.season,
                match: formatContent?.storyContainer?.data?.mam?._id, 
            };
    } 

    const TargetComponent = Sports[props.sport.normalize("NFD").replace(/[\u0300-\u036f]/g, "")];

    return (
        <>
            <TargetComponent {...props} />
            <link rel="stylesheet" href="//secure.widget.cloud.opta.net/v3/css/v3.football.opta-widgets.css" type="text/css" as="style" importance="high" />
            <Script src="//secure.widget.cloud.opta.net/v3/v3.opta-widgets.js" strategy="lazyOnload" />
            <Script
                strategy="afterInteractive"
                suppressHydrationWarning
                dangerouslySetInnerHTML={{
                    __html: `
                        window.opta_settings = {
                            subscription_id: '3499ef675eeff961d929353c007659f3',
                            language: 'es_CO',
                            timezone: 'America/Buenos_Aires'
                        };`
                }}
            />
        </>
    )
}

export default OptaWidget;