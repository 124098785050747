import {
  Authors,
  AuthorsInfoFooter,
  RelatedNews,
  RenderBody,
  Tag,
  Recommender,
  DiscoverNews,
  Trinity,
  NewsletterRelated,
  StoryHeader,
  Powerbeans
} from "@/templates/Clarin/Nota/components";
import {
  BannerCollapse
} from "@/shared/ContentBanner";
import RightColumn from "@/shared/RightColumn/index.jsx";
import SocialNetworking from "@/shared/SocialNetworking";
import { sizeBanner } from "@/shared/DFP/News/mapping";
import BannerLazy from "@/widgets/Banner/Templates/BannerLazy";
import Banner from "@/widgets/Banner/Templates/Banner";
import OptaWidget from "@/shared/OptaWidget";
import Viafoura from "@/shared/Viafoura";
import Separator from "@/shared/Separator";
import { NormalNewsContainer, StoryBody, StoryGrid, DataStory } from "./style";
import { StoryHeaderContainer } from "../../StoryHeader/style";
import FormattedDate from "@/shared/FormattedDate";
import { useAppTrue } from "@/hooks/index";
import { getTrinityCheckDate } from "../../../helpers";
import { useContext } from "react";
import TemplateContext from "@/context/Template/TemplateContext";
import { useRouter } from "next/router";

const Normal = () => {
  const router = useRouter();

  const dataContext = useContext(TemplateContext);
  const {
    idBucketDiscoverNews,
    idBucketRecommender,
    storyContainer } = dataContext?.content || {};

  const {
    _id,
    authors,
    onClickButtonSlider,
    canHaveComments,
    dataImage,
    dataVideo,
    freeHtml,
    hasRelated,
    innerWidth,
    isPreview,
    mam,
    newsSeo,
    publishedDate,
    relatedNews,
    sectionIdJWPlayerLead,
    summary,
    tags,
    title,
    titleEnable = true,
    trinityStatus = true,
    payWallAccessControl,
    adsPath,
    type,
  } = storyContainer?.data;

  const isApp = useAppTrue();
  const trinityDateStatus = getTrinityCheckDate(publishedDate);

  return (
    <>
    <NormalNewsContainer className="NewsContainer Nota-Normal">
      <StoryHeaderContainer className={`normal-header ${!hasRelated && "noRelated"}`}>
        <StoryHeader {...{ dataImage, dataVideo, freeHtml, hasRelated, mam, onClickButtonSlider, payWallAccessControl, sectionIdJWPlayerLead, title, titleEnable, summary, newsSeo, preloadImg: true }} />
      </StoryHeaderContainer>
      <StoryGrid>
        <StoryBody id="storyBody">
          <DataStory className="dataStory">
            <div className={`list-authors ${authors ? "has-border-top" : ""}`}>
              <Authors />
            </div>
            <div className="place-social-mobile">
              <SocialNetworking />
            </div>
          </DataStory>
          {trinityStatus && trinityDateStatus && <Trinity />}
          <Powerbeans/>
          <FormattedDate />
          <div className="StoryTextContainer vsmcontent">
            <div id="cuerpo">
              <RenderBody />
            </div>
          </div>
          {mam?.enabled && mam.sport !== "Tenis" && <OptaWidget />}
          {type != "recipe" && (
            <>
              {authors?.length > 0 && (
                <div className="sign-separator">
                  <Separator
                    sectionClass={"sepFullAdaptive"}
                    title="Sobre la firma"
                  />
                </div>
              )}
              <div className="list-bottom-authors">
                <AuthorsInfoFooter />
              </div>
            </>
          )}
          <div className="related-container">
            {relatedNews &&
              relatedNews.map((news, index) => (
                <RelatedNews key={`relatedNews${index}`} {...news} asPath={router.asPath}/>
              ))}
          </div>
          <NewsletterRelated />
          <Tag />
          <div className="comments">
            {canHaveComments && <Viafoura idNews={_id} />}
          </div>
          <BannerCollapse className="bannerCollapse">
            <BannerLazy isPreview={isPreview} slotId={"inread2"} adsPath={adsPath} mapping={sizeBanner.inread2} />
          </BannerCollapse>
          {
            !!idBucketRecommender
              ? <DiscoverNews title="Noticias destacadas" tag="p" innerWidth={innerWidth} isPreview={isPreview} idBucket={idBucketRecommender} />
              : <Recommender contentId={_id} tags={tags} />
          }
          <BannerCollapse className="bannerCollapse">
          <BannerLazy isPreview={isPreview} slotId={"inread3"} adsPath={adsPath} mapping={sizeBanner.inread3} />
          </BannerCollapse>
          <DiscoverNews title="Te puede interesar" tag="p" innerWidth={innerWidth} isPreview={isPreview} idBucket={idBucketDiscoverNews} />
          <DiscoverNews innerWidth={innerWidth} tag="p" isPreview={isPreview} title="Selección Clarín" idBucket="seleccion-clarin-lx" limit={3} suscribersOnly={true} idNew={_id} />
        </StoryBody>
        <div className="columnContainer">
          <div className="column">
            {innerWidth > 1023 && (<RightColumn />)}
          </div>
        </div>
      </StoryGrid>
    </NormalNewsContainer>
    { !isPreview && <div id="div-gpt-ad-flotante3" className="gptFlotante3"></div> }
    </>
  );
};

export default Normal;