export const getTrinityCheckDate = (publishedDate) => {
    const date2 = new Date(publishedDate);
    const date1 = new Date();
    date1.setFullYear(2023);
    date1.setMonth(7);
    date1.setDate(1);
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    const diff = date2.getTime() - date1.getTime();
    return diff >= 0 ? true : false;
}